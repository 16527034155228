import React, { useState, useEffect, useMemo } from "react";
import DiscoverLuxHeader from "../../components/DiscoverLux/Header";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import { apiRoutes } from "../../constants/apiRoutes";
import Layout from "../../components/Layout";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, navigate } from "gatsby";
import routes from "../../components/constants/routes";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";

const DLPastEvents = ({ location }) => {
  const [header, setHeader] = useState(undefined);
  const [pastSeasons, setPastSeasons] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [activeBlogId, setActiveBlogId] = useState(0);

  const params = new URLSearchParams(location.search);
  const blogId = params.get("id");

  useEffect(() => {
    if (blogId) {
      setActiveBlogId(blogId);
    }
  }, [blogId]);

  // useEffect(() => {
  //   if (pastSeasons.length) {
  //     setSelectedEvent(pastSeasons[0]);
  //   }
  // }, [pastSeasons]);

  const fetchPastSeasons = async () => {
    try {
      const { data } = await axios.get(apiRoutes.discoverluxPastEvent);
      if (data) {
        setPastSeasons(data.data);
      }
    } catch (error) {}
  };

  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
    fetchPastSeasons();
  }, []);

  useMemo(() => {
    if (pastSeasons.length) {
      const activeNews = pastSeasons.find((el) => el.id == activeBlogId);
      if (activeNews) {
        setSelectedEvent(activeNews);
        // setActiveFilm(activeNews.attributes.flims?.data);
      } else {
        setSelectedEvent(pastSeasons[0]);
        // setActiveFilm(pastSeasons[0].attributes.flims?.data);
      }
    }
  }, [pastSeasons, activeBlogId]);

  React.useEffect(() => {
    let counter = 0;
    const timer = setInterval(() => {
      const header = document.querySelector(".biffl-list-dropdown");
      counter++;
      if (header) {
        clearInterval(timer);
        window.addEventListener("scroll", function () {
          if (window.pageYOffset >= 310) {
            header.classList.add("biffl-dropdown-fixed");
            document.body.style.transition = "all 0.4s";
          } else {
            header.classList.remove("biffl-dropdown-fixed");
          }
        });
      } else if (counter >= 100) {
        clearInterval(timer);
      }
    }, 100);
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [selectedEvent]);

  const seoData = {
    title: "Discover Luxembourg Past Seasons | Relive City Adventures",
    description:
      " Relive the city adventures from Discover Luxembourg's past seasons. Explore the challenges, cultural experiences, and historical discoveries of previous events.",
    url: `${process.env.GATSBY_SITE_URL}${routes.DLPastEvents}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="discover-luxembourg">
          <div className="container-fluid p-mob">
            <div className="row">
              <div className="col-md-12">
                <div className="hero-section">
                  <img
                    src={createImage(
                      header?.discover_luxembourg_image?.data?.attributes?.url
                    )}
                    alt={header?.discover_luxembourg_image_alt_text}
                    width={"auto"}
                    height={"auto"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid" id="tab">
            <div className="row">
              <div className="col-md-12">
                <div className="head-tab mt-40">
                  <DiscoverLuxHeader />

                  {Object.values(selectedEvent).length ? (
                    <div className="tab-content" id="nav-tabContent">
                      <div className="discover-pastevent">
                        <div className="past-season">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-5 col-lg-3">
                                <div className="biffl-list mt-40">
                                  <ul>
                                    {pastSeasons?.map((el, index) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            navigate(
                                              `${routes.DLPastEvents}?id=${el.id}#tab`
                                            );
                                            setSelectedEvent(el);
                                          }}
                                          className={
                                            selectedEvent?.id === el.id
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <Link to="#tab">
                                            {el?.attributes?.menu_title}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>

                                <div className="biffl-list-dropdown">
                                  <div className="dropdown">
                                    <Link
                                      className="btn dropdown-toggle"
                                      to="#"
                                      role="button"
                                      id="dropdownMenuLink"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {selectedEvent?.attributes?.menu_title}
                                    </Link>

                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      {pastSeasons?.map((el, index) => {
                                        return (
                                          <li
                                            onClick={() => setSelectedEvent(el)}
                                          >
                                            {el?.attributes?.menu_title}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7 col-lg-9">
                                <div className="past-events-slider">
                                  <div className="past-events-title mt-60 mb-30">
                                    <h2>{selectedEvent?.attributes?.title}</h2>
                                    <div
                                      className="rich-editor"
                                      dangerouslySetInnerHTML={createMarkup(
                                        selectedEvent?.attributes?.sub_title
                                      )}
                                    ></div>
                                  </div>
                                  <Swiper
                                    modules={[
                                      Navigation,
                                      Pagination,
                                      Scrollbar,
                                      A11y,
                                      Autoplay,
                                    ]}
                                    spaceBetween={20}
                                    // slidesPerView={2}
                                    loop={true}
                                    navigation
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                      delay: 5000,
                                      disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                      640: {
                                        slidesPerView: 1,
                                      },
                                      1000: {
                                        slidesPerView: 1,
                                      },
                                      1300: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                  >
                                    {selectedEvent?.attributes?.event_image?.data?.map(
                                      (el) => (
                                        <SwiperSlide>
                                          <div className="latest-news-card">
                                            <img
                                              src={createImage(
                                                el?.attributes?.url
                                              )}
                                              alt={
                                                selectedEvent?.attributes
                                                  ?.event_image_alt_text
                                              }
                                              width={"auto"}
                                              height={"auto"}
                                            />
                                          </div>
                                        </SwiperSlide>
                                      )
                                    )}
                                  </Swiper>

                                  {selectedEvent?.attributes?.youtube_url ? (
                                    <div className="current-season-yt mt-40">
                                      <iframe
                                        width="826"
                                        height="468"
                                        src={
                                          selectedEvent?.attributes?.youtube_url
                                        }
                                      ></iframe>
                                    </div>
                                  ) : null}

                                  <section className="results">
                                    {selectedEvent?.attributes?.Results
                                      ?.length ? (
                                      <>
                                        <h2>Results</h2>
                                        <div className="container">
                                          <div className="row justify-content-center">
                                            <div className="col-md-3 col-lg-2 col-3">
                                              <div className="pastwinners-year">
                                                <p className="years">
                                                  Position
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-9 col-lg-6 col-9">
                                              <div className="pastwinners-list">
                                                <p className="winners">Film</p>
                                              </div>
                                            </div>
                                          </div>
                                          {selectedEvent?.attributes?.Results?.map(
                                            (el) => (
                                              <div className="row justify-content-center">
                                                <div className="col-md-3 col-lg-2 col-3">
                                                  <div className="pastwinners-year">
                                                    {/* <p className="years">Year</p> */}
                                                    <p className="years-details">
                                                      {el?.Position}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-md-9 col-lg-6 col-9">
                                                  <div className="pastwinners-list">
                                                    {/* <p className="winners">Winner</p> */}
                                                    <p className="winner-details">
                                                      {el?.Flim}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </>
                                    ) : null}

                                    <div
                                      className="rich-editor text-center mt-lg-4 word-break"
                                      dangerouslySetInnerHTML={createMarkup(
                                        selectedEvent?.attributes?.photo_url
                                      )}
                                    ></div>

                                    <div className="container padding-mob">
                                      {selectedEvent?.attributes?.event_partners
                                        ?.data?.length ? (
                                        <>
                                          <h2 className="results-title">
                                            Event Partners
                                          </h2>
                                          <div className="row justify-content-center">
                                            {selectedEvent?.attributes?.event_partners?.data?.map(
                                              (el) => (
                                                <div className="col-md-3 col-6">
                                                  <div className="event-partner-box">
                                                    <img
                                                      src={createImage(
                                                        el?.attributes
                                                          ?.sponsor_image?.data
                                                          ?.attributes?.url
                                                      )}
                                                      alt={
                                                        el?.attributes
                                                          ?.sponsor_image_alt_text
                                                      }
                                                      width={"auto"}
                                                      height={"auto"}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : null}
                                      {selectedEvent?.attributes
                                        ?.station_sponsors.data?.length ? (
                                        <>
                                          <h2 className="results-title">
                                            Station Sponsors
                                          </h2>
                                          <div className="row justify-content-center">
                                            {selectedEvent?.attributes?.station_sponsors?.data?.map(
                                              (el) => (
                                                <div className="col-6 col-md-3">
                                                  <div className="event-partner-box">
                                                    <img
                                                      src={createImage(
                                                        el?.attributes
                                                          ?.sponsor_image?.data
                                                          ?.attributes?.url
                                                      )}
                                                      alt={
                                                        el?.attributes
                                                          ?.sponsor_image_alt_text
                                                      }
                                                      width={"auto"}
                                                      height={"auto"}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : null}

                                      {selectedEvent?.attributes
                                        ?.sponsors_media_patners?.data
                                        ?.length ? (
                                        <>
                                          <h2 className="mt-50 mb-20">
                                            Sponsors Media Partners
                                          </h2>
                                          <div className="row event-partners justify-content-center">
                                            {selectedEvent?.attributes?.sponsors_media_patners?.data?.map(
                                              (el) => (
                                                <div className="col-md-3 col-6">
                                                  <div className="event-partner-box">
                                                    <img
                                                      src={createImage(
                                                        el?.attributes
                                                          ?.sponsor_image?.data
                                                          ?.attributes?.url
                                                      )}
                                                      alt={
                                                        el?.attributes
                                                          ?.sponsor_image_alt_text
                                                      }
                                                      width={"auto"}
                                                      height={"auto"}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : null}

                                      {selectedEvent?.attributes?.charity?.data
                                        ?.length ? (
                                        <>
                                          <h2 className="mt-50 mb-20">
                                            Charity
                                          </h2>
                                          <div className="row event-partners justify-content-center">
                                            {selectedEvent?.attributes?.charity?.data?.map(
                                              (el) => (
                                                <div className="col-md-3 col-6">
                                                  <div className="event-partner-box">
                                                    <img
                                                      src={createImage(
                                                        el?.attributes
                                                          ?.sponsor_image?.data
                                                          ?.attributes?.url
                                                      )}
                                                      alt={
                                                        el?.attributes
                                                          ?.sponsor_image_alt_text
                                                      }
                                                      width={"auto"}
                                                      height={"auto"}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center" style={{ margin: "15em 0" }}></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DLPastEvents;
